<template>
    <div class="">
        <div class="useful-links" v-if="initLoading && currrentOrganisationId && links?.length">
            <div class="d-flex align-items-center">
                <b-img :src="headerImage" alt="usefulLinksImage" class="useful-links-image"></b-img>
            </div>

            <div class="row pb-6 mt-6">
                <div v-for="link in filteredLinks" :key="link.id" class="col col-4">
                    <b-card class="st-section mt-6 useful-links-items">
                        <div class="st-section-body useful-link">
                            <a :href="`${link.Link}`" target="_blank">{{ link.Nume }}</a>
                        </div>
                    </b-card>
                </div>
            </div>
        </div>

        <select-organisation-modal
            ref="ulSelectOrganisationModal"
            @organisationSelected="organisationSelected"
        ></select-organisation-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Roles from '@/security/roles';
import SelectOrganisationModal from '@/modules/administrator/components/modals/SelectOrganisationModal.vue';

export default {
    name: 'UsefulLinks',
    components: {
        SelectOrganisationModal
    },
    data() {
        return {
            initLoading: false,
            headerImage: `${process.env.BASE_URL}media/useful-links.png`,
        };
    },
    watch: {
        selectedOrg: function (org) {
            if(org?.id) {
                this.getUsefulLinks(org.id);
            }
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
            selectedOrg: "shared/organisation",
            accountDetails: "auth/accountDetails",
            isStaff: 'auth/isStaff',
            links: 'usefulLinks/links'
        }),
        isLoading() {
            return this.loading['organisations/getUsefulLinks'];
        },
        isSuperAdmin() {
            return this.currentUser.user_type_name === Roles.values['super_admin']
        },
        filteredLinks() {
            return this.links?.filter(v => v.Nume && v.Link);
        },

        currrentOrganisationId() {
            if(this.isSuperAdmin) {
                return this.selectedOrg?.id;
            } else if (this.isStaff) {
                return this.accountDetails?.organisation_id;
            }
            return null;
        }
    },
    methods: {
        ...mapActions({
            getUsefulLinks: 'usefulLinks/getOrganisationLinks',
        }),
        organisationSelected() {
            this.initLoading = true;
            this.$refs.ulSelectOrganisationModal.hide();
        },

    },
    mounted() {
        if (this.isSuperAdmin) {
            this.initLoading = false;
            this.$refs.ulSelectOrganisationModal.show();
        } else {
            this.initLoading = true;
        }
    },
    created() {
        if(this.currrentOrganisationId) {
            this.getUsefulLinks(this.currrentOrganisationId);
        }
    },
}
</script>
<style lang="scss">
.useful-links-image {
    margin-top: 2em;
    margin-left: 25%;
    width: 50%;
    z-index: 100;
}
.useful-links-items {
    padding: 10px 30px;
    margin: 20px 30px;
    border-radius: 25px!important;
    text-align: center;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .card-body {
        display: flex;
        align-items: center;
        padding: 1rem;
    }
}
.useful-link {
    display: block;
    overflow: hidden;
    font-size: 1.25rem;
    a {
        color: #002A78;
    }
}

</style>
